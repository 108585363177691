import Slider from "../container/slider/slider";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Data,ProductsData} from "../helper/data";
import ProductBox from "../container/product/product";

function Home() {
    return(
        <>
            <section>
                <Slider />
            </section>
            <section className={"text-center py-md-5"} style={{backgroundColor:"#f7f7f7"}}>
                <Container style={{maxWidth:750}}>
                    <Row>
                        <Col md={12}>
                            <h1 className={"fw-bolder text-uppercase"}>Sarabtech for Business</h1>
                            <p className={"opacity-75 my-md-4 my-3"}>Discover unparalleled software excellence, empowering your journey towards seamless productivity and limitless creativity.</p>

                            <Link  to={"/collections/all"} className={"btn btn-dark "}>Browse Plans</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={"py-md-5 py-4"}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 className={"fw-bolder mb-3 text-uppercase"}>Marketing Plans & Packages</h3>
                            <Row>
                                {
                                    ProductsData.filter((v:any)=>{return v.cat == "marketing"}).map((v:any,i:number)=>{
                                        if(i > 3){ return }
                                        return(
                                            <ProductBox data={v} index={i} key={i} col={3} />
                                        )
                                    })
                                }

                                <Col md={12} className={"text-center mt-3"}>

                                    <Link className={"btn btn-dark"} to={"/products/marketing"}>VIEW ALL</Link>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <Row className={"py-5 my-4"}>
                        <Col md={12}>
                            <h3 className={"fw-bolder  text-uppercase"}>Remote IT Support</h3>
                            <Row>
                                {
                                    ProductsData.filter((v:any)=>{return v.cat == "it-support"}).map((v:any,i:number)=>{
                                        if(i > 2){ return }
                                        return(
                                            <ProductBox data={v} index={i} key={i} col={4} />
                                        )
                                    })
                                }

                                <Col md={12} className={"text-center mt-3"}>
                                    <Link className={"btn btn-dark"} to={"/products/it-support"}>VIEW ALL</Link>
                                </Col>


                            </Row>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={12}>
                            <h3 className={"fw-bolder mb-3 text-uppercase"}>Shopify Plans</h3>
                            <Row>
                                {
                                    ProductsData.filter((v:any)=>{return v.cat == "shopify-service-packages"}).map((v:any,i:number)=>{
                                        if(i > 3){ return }
                                        return(
                                            <ProductBox data={v} index={i} key={i} col={3} />

                                        )
                                    })
                                }


                                <Col md={12} className={"text-center mt-3"}>
                                    <Link className={"btn btn-dark"} to={"/products/shopify-service-packages"}>VIEW ALL</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}
export default Home
