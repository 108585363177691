import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Data, ProductCategory, ProductsData} from "../helper/data";
import {Breadcrumb, Button, Col, Container, Image, Row} from "react-bootstrap";
import ProductBox from "../container/product/product";
import Addtocart from "../container/cart/addtocart";

function Product() {
    const {id} = useParams()
    const [data,setdata] = useState({} as any)
    const [cat,setcat] = useState({} as any)
    const [counter,setcounter] = useState(1)

    useEffect(()=>{
        const index = ProductsData.findIndex((v:any)=>{
            return v.title.replaceAll(" ","-").replaceAll(":","").toLowerCase() == id
        });
        if(index >=0){

            setdata(ProductsData[index])

            const index2 = ProductCategory.findIndex((v:any)=>{
                return v.url.split("/")[2] == ProductsData[index].cat
            });
            if(index2 >=0){
                setcat(ProductCategory[index2])
            }

        }

    },[id])


    const setCounters = (s:string) => {
        const input = document.getElementById("Quantity") as HTMLInputElement
        if(input){
            const v = input.value
            if(s == "+"){
                if(parseInt(input.value) < 10){
                    input.value = (parseInt(input.value) + 1).toString()
                    setcounter(parseInt(v) + 1)
                }
            }else{
                if(parseInt(input.value) > 1){
                    input.value = (parseInt(input.value) - 1).toString()
                    setcounter(parseInt(v) - 1)
                }
            }
        }

    }
    return(
        <>
            <Container className={"py-md-4 py-4 mb-md-5 "} fluid={"md"} style={{maxWidth:1200}}  >
                <Row className={"justify-content-center"}>
                    <Col md={11}>
                        <Breadcrumb>
                            <Breadcrumb.Item as={Link} to="/collections/all">Category</Breadcrumb.Item>
                            <Breadcrumb.Item as={Link} to={cat.url}>{cat.title}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className={"justify-content-center"} >
                    <Col md={6}>
                        <div className={"ratio ratio-1x1"} style={{backgroundColor:"#f7f7f7"}}>
                            <Image srcSet={data.image} width={"100%"} />
                        </div>
                    </Col>
                    <Col md={5}>
                        <span className={"text-uppercase opacity-50"} style={{fontSize:12}}>rozmati</span>
                        <h1 className={"fw-bold"}>{data.title}</h1>
                        <p>
                            {
                                data.discount &&
                                <span className={"text-decoration-line-through opacity-50 me-2"} style={{fontSize:17}} >{data.discount} {Data.currancy}</span>
                            }
                            <span style={{fontSize:22}}>{data.price} {Data.currancy}</span>
                        </p>

                        <div className={"py-3"}>
                            <div className={""}>Quantity</div>
                            <div className={"quantity"} style={{border:"1px solid #000"}}>
                                <Button variant={"none"} onClick={()=>{setCounters("-")}}  className={"quantity__input h-auto"}>
                                    <svg width={10} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                         className="icon icon-minus" fill="none" viewBox="0 0 10 2">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z" fill="currentColor">
                                        </path>
                                    </svg>
                                </Button>
                                <input className="quantity__input" readOnly  name="quantity" id="Quantity" min="1" step="1" defaultValue={counter} max="10" />
                                <Button variant={"none"} onClick={()=>{setCounters("+")}} className={"quantity__input h-auto"}>
                                    <svg width={10} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                         className="icon icon-plus" fill="none" viewBox="0 0 10 10">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                                              fill="currentColor">
                                        </path>
                                    </svg>
                                </Button>
                            </div>
                        </div>

                        <div>
                            <Addtocart id={id} counter={counter} variant={"outline-dark"} className={"w-100 rounded-0"} />
                            <Button className={"w-100 rounded-0 mt-2"} variant={"dark"}>Buy it now</Button>
                        </div>

                        <div className={"pt-3"}  dangerouslySetInnerHTML={{__html:data.description}}>

                        </div>
                    </Col>
                </Row>
            </Container>
            <section className={"py-5 mt-4"} style={{backgroundColor:"#f7f7f7"}}>
                <Container>
                    <Row>
                        {
                            ProductsData.filter((v:any)=>{return v.title != data.title}).map((v:any,i:number)=>{
                                if(i > 3){ return }
                                return(
                                    <ProductBox data={v} index={i} key={i} col={3} />
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Product
