import {Col, Container, Image, Row, Breadcrumb, Button} from "react-bootstrap";
import {Data, ProductCategory, ProductsData} from "../helper/data";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ProductBox from "../container/product/product";

function Products() {
    const {id} = useParams()
    const [data,setdata] = useState([] as any)
    const [cat,setcat] = useState({} as any)

    useEffect(()=>{
        const index = ProductCategory.findIndex((v:any)=>{
            return v.url.split("/")[2] == id
        });
        if(index >=0){
            setcat(ProductCategory[index])
        }
        const d = ProductsData.filter((v:any)=>{
            return v.cat == id
        })
        setdata(d);
    },[id])

    return(
        <Container className={"py-md-4 py-4 mb-md-5 "} fluid={"md"}  >
            <Row>
                <Col md={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item as={Link} to="/collections/all">Category</Breadcrumb.Item>
                        <Breadcrumb.Item active>{cat.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h1 className={"title-page fw-bolder pt-4"}>{cat.title}</h1>
                </Col>


                {
                    data.map((v:any,i:number)=>{
                        return(
                            <ProductBox data={v} index={i} key={i} col={3} />
                        )
                    })
                }

            </Row>
        </Container>
    )
}
export default Products
