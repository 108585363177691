import {Link} from "react-router-dom";
import {Button, Col, Image} from "react-bootstrap";
import {Data} from "../../helper/data";
import Addtocart from "../cart/addtocart";

function ProductBox({data=[] as any,index=0,col=3}) {
    return(
        <>
            <Col md={col} xs={6} className={"mb-4"} key={index}>
                <Link to={"/product/"+data.title.replaceAll(" ","-").replaceAll(":","").toLowerCase()} className={"d-block"}>
                    <div className={"ratio ratio-1x1"}>
                        <div className={"overflow-hidden"}>
                            <Image srcSet={data.image} className={"position-absolute top-50 start-50 translate-middle object-fit-cover"} width={"100%"} height={"100%"} style={{objectPosition:"center center"}}  />
                        </div>
                    </div>
                    <p className={"py-0  my-0 mt-1"}>{data.title}</p>
                    <p>
                        {
                            data.discount &&
                            <span className={"text-decoration-line-through opacity-50 me-2"} style={{fontSize:15}}>{data.discount} {Data.currancy}</span>
                        }
                        <span>{data.price} {Data.currancy}</span>
                    </p>
                </Link>
                <Addtocart id={data.title.replaceAll(" ","-").replaceAll(":","").toLowerCase()} counter={1} variant={"outline-dark"} className={"w-100 rounded-0"} />

             </Col>
        </>
    )
}
export default ProductBox
