import {Image} from "react-bootstrap";
import slider from "../../assets/images/banner.webp"
import {Link} from "react-router-dom";
function Slider() {
    return(
        <Link to={"/collections/all"}>
            <Image srcSet={slider} width={"100%"} />
        </Link>
    )
}
export default Slider
